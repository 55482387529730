import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import dateFormat from "dateformat";

const HistoryRow = ({ data }) => {
  const [date, setDate] = useState("");
  const [narration, setNarration] = useState("N/A");

  const { useremailaddress, amount, paymentmode, extras } = data;

  useEffect(() => {
    try {
      let date = JSON.parse(extras);
      const { TransDate, Narration } = JSON.parse(date.data);
      setDate(TransDate);
      setNarration(Narration);
    } catch (e) {}
  }, []);

  return (
    <li class="table-row">
      <div
        class="col col-1"
        data-label="Method"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {paymentmode}
      </div>
      <div
        class="col col-2"
        data-label="Amount"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        GH₵ {amount}
      </div>
      <div
        class="col col-3"
        data-label="Method"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {narration}
      </div>
      <div
        class="col col-4"
        data-label="Date"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {dateFormat(date, "dS mmmm, yyyy")}
      </div>
    </li>
  );
};

export default HistoryRow;
