import Cookies from "js-cookie";
import React, { useState } from "react";
import { useAuth } from "../context/AuthProvider";
import { useEnv } from "../context/env.context";

export const useExternalAPI = () => {
  const { apiServerUrl, axios } = useEnv();
  const { logout } = useAuth();

  axios.interceptors.response.use(
    function (response) {
      if (response?.data?.message === "Un-authenticated!") {
        loginOutUser();
        return;
      }
      return response;
    },
    function (error) {
      if (
        error.response?.status === 400 &&
        error.response?.data?.message === "Un-authenticated!"
      ) {
        loginOutUser();
        return;
      }

      return {
        success: false,
        message:
          error?.message === "Network Error"
            ? error?.message
            : error?.response?.data.message,
      };
    }
  );

  const setAuthToken = (token) => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else delete axios.defaults.headers.common["Authorization"];
  };

  const makeRequest = async (options) => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        setAuthToken(token);
      }
      const response = await axios({ ...options.config, timeout: 15000 });
      // console.log("Response: ", response);
      return response.data || response;
    } catch (error) {
      // console.log("Error: ", error);
      return error;
    }
  };

  const getOTP = async (email) => {
    const config = {
      url: `${apiServerUrl}/api/auth/precheck`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: {
        emailaddress: email,
         authv: "V2"
      },
      // withCredentials: true,
    };

    const data = await makeRequest({
      config,
    });

    return data;
  };

  const verifyEmail = async (email, otp) => {
    const config = {
      url: `${apiServerUrl}/api/auth/emailotpverify`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: {
        emailaddress: email,
        otpcode: otp,
      },
      // withCredentials: true,
    };

    const data = await makeRequest({
      config,
    });

    return data;
  };

  const addPendingAccount = async (userData) => {
    const config = {
      url: `${apiServerUrl}/api/auth/${
        userData.preUser ? "adduserapproved" : "adduserpending"
      }`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: { ...userData, typeofuser: userData.typeofuser.toUpperCase() },
      // withCredentials: true,
    };

    const data = await makeRequest({
      config,
    });

    return data;
  };

  const getSchools = async (userData) => {
    const config = {
      url: `${apiServerUrl}/api/general/fetchuniversities`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: {
        id: "1",
        statusresponse: "APPROVE",
      },
      // withCredentials: true,
    };

    const data = await makeRequest({
      config,
    });

    return data;
  };

  const getRegions = async (userData) => {
    const config = {
      url: `${apiServerUrl}/api/general/fetchregions`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: {
        id: "1",
        statusresponse: "APPROVE",
      },
    };

    const data = await makeRequest({
      config,
    });

    return data;
  };

  const getArea = async (userData) => {
    const config = {
      url: `${apiServerUrl}/api/general/fetchareasofpractise`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: {
        id: "1",
        statusresponse: "APPROVE",
      },
    };

    const data = await makeRequest({
      config,
    });

    return data;
  };

  const getChapter = async (userData) => {
    const config = {
      url: `${apiServerUrl}/api/general/fetchchapters`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: {
        id: "1",
        statusresponse: "APPROVE",
      },
    };

    const data = await makeRequest({
      config,
    });

    return data;
  };

  const getChamber = async (userData) => {
    const config = {
      url: `${apiServerUrl}/api/general/fetchchambers`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: {
        id: "1",
        statusresponse: "APPROVE",
      },
    };

    const data = await makeRequest({
      config,
    });

    return data;
  };

  const getProvince = async (userData) => {
    const config = {
      url: `${apiServerUrl}/api/general/fetchprovinces`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: {
        id: "1",
        statusresponse: "APPROVE",
      },
    };

    const data = await makeRequest({
      config,
    });

    return data;
  };

  const loginUser = async (loginData) => {
    const config = {
      url: `${apiServerUrl}/api/auth/webuserlogin`,
      method: "POST",
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: { ...loginData, authv: "V2" },
    };

    const data = await makeRequest({ config });

    if (data?.success) {
      const token = data.token;

      //set JWT token to local
      localStorage.setItem("temporaryuser", "");
      localStorage.setItem("token", token);

      //set token to axios common header
      setAuthToken(token);
    }

    return data;
  };

  const loginOutUser = async () => {
    const config = {
      url: `${apiServerUrl}/api/auth/logout`,
      method: "POST",
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: {},
    };

    const data = await makeRequest({ config });

    localStorage.setItem("token", "");

    setAuthToken(null);
    logout();

    return null;
  };

  const getResetOTP = async (email) => {
    const config = {
      url: `${apiServerUrl}/api/auth/initpasswordreset`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: {
        emailaddress: email,
      },
      // withCredentials: true,
    };

    const data = await makeRequest({
      config,
    });

    return data;
  };

  const passwordReset = async (userData) => {
    const config = {
      url: `${apiServerUrl}/api/auth/passwordreset`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: userData,
      // withCredentials: true,
    };

    const data = await makeRequest({
      config,
    });

    return data;
  };

  const queryPaymentAccount = async (userData) => {
    const config = {
      url: `${apiServerUrl}/api/payment/getaccountname`,
      method: "POST",
      withCredentials: true,
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: userData,
    };

    const data = await makeRequest({
      config,
    });

    return data;
  };

  const makeUserPayment = async (userData) => {
    const config = {
      url: `${apiServerUrl}/api/payment/makeuserpayment`,
      method: "POST",
      withCredentials: true,
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: userData,
    };

    const data = await makeRequest({
      config,
    });

    return data;
  };

  const checkMomoStatus = async (userData) => {
    const config = {
      url: `${apiServerUrl}/api/payment/checkmomotransstaus`,
      method: "POST",
      withCredentials: true,
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: userData,
    };

    const data = await makeRequest({
      config,
    });

    return data;
  };

  const changeProfile = async (userData) => {
    const config = {
      url: `${apiServerUrl}/api/auth/updateprofilepicture`,
      method: "POST",
      withCredentials: true,
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: userData,
    };

    const data = await makeRequest({
      config,
    });

    return data;
  };

  const chaneProfileData = async (userData) => {
    const config = {
      url: `${apiServerUrl}/api/auth/updateuserprofile`,
      method: "POST",
      withCredentials: true,
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: userData,
    };

    const data = await makeRequest({
      config,
    });

    return data;
  };

  const changePassword = async (userData) => {
    const config = {
      url: `${apiServerUrl}/api/auth/passwordresetv2`,
      method: "POST",
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: userData,
    };

    const data = await makeRequest({ config });

    return data;
  };

  const fecthUserPayments = async (userData) => {
    const config = {
      url: `${apiServerUrl}/api/payment/userfetchpaymenttransaction`,
      method: "GET",
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      data: userData,
    };

    const data = await makeRequest({ config });

    return data;
  };

  return {
    getOTP,
    verifyEmail,
    getSchools,
    addPendingAccount,
    loginUser,
    loginOutUser,
    getResetOTP,
    passwordReset,
    getRegions,
    getArea,
    getChapter,
    getChamber,
    getProvince,
    queryPaymentAccount,
    makeUserPayment,
    changeProfile,
    checkMomoStatus,
    chaneProfileData,
    changePassword,
    fecthUserPayments,
  };
};
