import React, { useEffect, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { PropagateLoader } from "react-spinners";
import { colors } from "../../utils/colors";
import {
  AccessForm,
  DataListInput,
  FormInput,
} from "../../components/styles/Access";
import { GlobalButton, RowDivSpace } from "../../components/styles/Global";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useExternalAPI } from "../../hooks/useExternalAPI";
import "../../components/styles/Customcheckbox.css";
import { DuesCheck, DuesDot, DuesTableRow } from "../../components/styles/Dues";
import AnimateHeight from "react-animate-height";
import {
  DashSearchContainer,
  IconDashReceipt,
  IconDashRight,
} from "../../components/styles/Dashboard";
import PopupView from "../../components/General/PopupPendingView";
import Step3 from "../../components/PendingDue/Step3";
import { useAuth } from "../../context/AuthProvider";
import { IoNotificationsCircleSharp } from "react-icons/io5";

const Dues = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [payingList, setPayingList] = useState([
    {
      id: 1,
      amount: 240,
    },
  ]);
  const [total, setTotal] = useState(0);
  const [drop, setDrop] = useState(false);
  const [payView, setPayView] = useState(false);
  const { user } = useAuth();

  const {
    fullregionname,
    phonenumber,
    region,
    typeofuser,
    fullchapter,
    fullprovice,
    fullareaofp,
    fullchamber,
    userpaymentdata,
  } = user;

  useEffect(() => {
    let total = [];
    payingList.forEach(({ amount }) => total.push(amount));
    total = total === [] ? 0 : total.reduce((a, b) => a + b, 0);
    setTotal(total);
  }, [payingList]);

  const exist = (id, checked, amount) => {
    const exist = payingList.find((element) => {
      if (element.id === id) {
        return true;
      }

      return false;
    });

    if (exist && !checked) {
      setPayingList((e) => e.filter((data) => data.id !== id));
    }
    if (!exist && checked) {
      setPayingList((e) => [...e, { amount, id }]);
    }
  };

  const form = useRef();
  const fileTypes = ["JPG", "PNG"];

  const request = () => {};

  return (
    <div style={{ marginTop: 20, display: "flex" }}>
      {userpaymentdata?.allpaymentunpaid.length === 0 ? (
        <DashSearchContainer
          style={{
            margin: "10px 0",
            padding: 10,
            justifyContent: "space-between",
            alignItems: "center",
            border: "1px solid rgba(0, 0, 0, 0.09)",
            backgroundColor: "#f8f8fa",
            fontSize: 14,
            height: "max-content"
          }}
        >
          Currently don't have any pending approvals
          <IoNotificationsCircleSharp color={colors.primary} size={30} />
        </DashSearchContainer>
      ) : (
        <div style={{ flex: 1 }}>
          <p
            style={{
              color: "black",
              marginBottom: 25,
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              borderRadius: 5,
              padding: "5px 7px",
            }}
          >
            Here is a list of outstanding payments.
          </p>
          <div style={{ width: "100%" }}>
            <div
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                borderRadius: 5,
                padding: 5,
              }}
            >
              <h4
                style={{
                  marginBottom: 10,
                  fontSize: 14,
                  color: colors.primary,
                }}
              >
                Mandatory Dues
              </h4>
              {userpaymentdata?.allpaymentunpaid?.map((data, index) => (
                <DuesTableRow key={index}>
                  {/* <DuesCheck>
              <DuesDot active={true} />
            </DuesCheck> */}
                  <label class="container">
                    <input
                      type={"checkbox"}
                      checked={true}
                      disabled={true}
                      style={{ marginRight: 5 }}
                      // onChange={(e) => setCheck(e.target.checked)}
                    />
                    <span class="checkmark" />
                  </label>
                  <p style={{ flex: 1, color: "black", fontSize: 14 }}>
                    Annual dues
                    <p style={{ fontSize: 12, color: "grey" }}>
                      {data.desctext}
                    </p>
                  </p>
                  <p style={{ color: "black", fontSize: 16 }}>
                    GH₵ {data.amount}
                  </p>
                </DuesTableRow>
              ))}
            </div>
            {/* <div
          style={{
            border: "1px solid rgba(0,0,0,0.1)",
            borderRadius: 5,
            padding: 5,
            marginTop: 20,
          }}
        >
          <h4 style={{ marginBottom: 10, fontSize: 14, color: "#219ebc" }}>Optional Dues</h4>
          <DuesTableRow>
             <DuesCheck>
            <DuesDot active={exist(2)} />
          </DuesCheck> 
            <label class="container">
              <input
                type={"checkbox"}
                style={{ marginRight: 5 }}
                onChange={(e) => exist(2, e.target.checked, 50)}
              />
              <span class="checkmark" />
            </label>
            <p style={{ flex: 1, color: "black", fontSize: 14 }}>
              Accra Chapter Dues
            </p>
            <p style={{ color: "black", fontSize: 16 }}>GH₵ 50.00</p>
          </DuesTableRow>
          <DuesTableRow>
             <DuesCheck>
            <DuesDot active={exist(2)} />
          </DuesCheck> 
            <label class="container">
              <input
                type={"checkbox"}
                style={{ marginRight: 5 }}
                onChange={(e) => exist(3, e.target.checked, 100)}
              />
              <span class="checkmark" />
            </label>
            <p style={{ flex: 1, color: "black", fontSize: 14 }}>
              Student Entrance Dues
            </p>
            <p style={{ color: "black", fontSize: 16 }}>GH₵ 100.00</p>
          </DuesTableRow>
        </div>
        <div
          style={{
            border: "1px solid rgba(0,0,0,0.1)",
            borderRadius: 5,
            padding: 5,
            marginTop: 20,
          }}
        >
          <h4 style={{ marginBottom: 10, fontSize: 14 }}>
            Optional Minimum Dues
          </h4>
          <DuesTableRow>
            <label class="container">
              <input
                type={"checkbox"}
                style={{ marginRight: 5 }}
                onChange={(e) => exist(3, e.target.checked, 55)}
              />
              <span class="checkmark" />
            </label>
            <p style={{ flex: 1, color: "black", fontSize: 14 }}>Donation</p>
            <p style={{ color: "black", fontSize: 16, display: "flex", justifyContent: "right", alignItems: "center" }}>
               <span
                style={{
                  color: "gray",
                  fontSize: 11,
                }}
              >
                minimum of{" "}
              </span>{" "} 
              GH₵ 55.00
              {!drop ? (
                <GlobalButton
                  background={"green"}
                  color={"white"}
                  style={{
                    margin: 0,
                    borderRadius: 5,
                    padding: "10px 20px",
                    display: "inline",
                    marginLeft: 5,
                  }}
                  type="button"
                  onClick={() => setDrop(true)}
                >
                  change
                </GlobalButton>
              ) : null}
            </p>
          </DuesTableRow>
          <AnimateHeight height={drop ? "auto" : 0}>
            <div style={{ display: "flex" }}>
              <FormInput type="number" placeholder="input amount" />
              <GlobalButton
                background={"green"}
                color={"white"}
                style={{
                  margin: 0,
                  borderRadius: 5,
                  padding: "10px 20px",
                  display: "inline",
                  marginLeft: 5,
                  height: "max-content",
                  alignSelf: "flex-end",
                }}
                type="button"
                onClick={() => setDrop(false)}
              >
                confirm
              </GlobalButton>
            </div>
          </AnimateHeight>
        </div>*/}
            <DuesTableRow
              style={{ marginTop: 30, border: "none", color: "#3a5a40" }}
            >
              <p style={{ flex: 1, fontSize: 14, fontWeight: "bold" }}>
                Total Amount Due
              </p>
              <h2 style={{ fontSize: 16 }}>GH₵ {total}</h2>
            </DuesTableRow>
          </div>
          <p style={{ fontSize: 12, color: "red", marginTop: 5 }}>{error}</p>
          <GlobalButton
            background={"green"}
            color={"white"}
            style={{
              margin: 0,
              borderRadius: 5,
              padding: "10px 20px",
              display: "inline",
              marginTop: 10,
            }}
            type="button"
            onClick={() => setPayView(true)}
          >
            {loading ? (
              <span style={{ padding: 10, marginTop: -10, marginBottom: 7 }}>
                <PropagateLoader color={"white"} loading={loading} size={15} />
              </span>
            ) : (
              "Pay the selected above dues"
            )}
          </GlobalButton>
        </div>
      )}
      <div
        style={{
          padding: 10,
          border: "1px solid #E8E4E0",
          borderRadius: 10,
          width: 250,
          height: "max-content",
          marginLeft: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h5>Receipts</h5>
          <IconDashReceipt />
        </div>
        <p style={{ fontSize: 12, marginTop: 10, marginBottom: 5 }}>
          Payments, donations, resources etc.
        </p>
        <RowDivSpace
          style={{ fontSize: 14, marginTop: 10, cursor: "pointer" }}
          // onClick={() => direct("/dashboard/dues")}
        >
          Mandatory
          <IconDashRight />
        </RowDivSpace>
        <RowDivSpace
          style={{
            fontSize: 14,
            marginTop: 10,
            marginBottom: 10,
            cursor: "pointer",
          }}
          // onClick={() => direct("/dashboard/dues")}
        >
          Optional
          <IconDashRight />
        </RowDivSpace>
        <a
          style={{
            color: colors.primary,
            cursor: "pointer",
            fontSize: 13,
            fontWeight: "bold",
            marginTop: 10,
          }}
        >
          View all
        </a>
      </div>
      <PopupView payView={payView} setPayView={setPayView}>
        <Step3 />
      </PopupView>
    </div>
  );
};

export default Dues;
