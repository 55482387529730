import React, { useRef, useState } from "react";
import { PropagateLoader } from "react-spinners";
import { colors } from "../../utils/colors";
import { AccessForm, DataListInput, FormInput } from "../styles/Access";
import { GlobalButton } from "../styles/Global";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useExternalAPI } from "../../hooks/useExternalAPI";

const Step00 = ({ setEmail, setStep, setType }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { getOTP } = useExternalAPI();
  const form = useRef();

  const request = async (e) => {
    e.preventDefault();
    if (loading) return;
    setError("");
    setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    //   setEmail(e.target[1].value);
    //   setType(e.target[0].value);
    //   setStep(1)
    //   console.log("Form data: ", e);
    // }, 2000);
    const request = await getOTP(e.target[1].value);
    // console.log("Request response: ", request);
    if (request?.newuser === false) {
      setError(request.message);
      setLoading(false)
      return
    }
    if (request?.success === true) {
      setEmail(e.target[1].value);
      setType(e.target[0].value);
      setStep(1);
    } else {
      setError(request?.message);
    }
    setLoading(false);
  };

  return (
    <AccessForm
      key={0}
      onSubmit={(e) => (!false ? request(e) : null)}
      ref={form}
    >
      Select account type *
      <DataListInput style={{ marginBottom: 10 }} required>
        <option value="" disabled selected>
          choose type
        </option>
        <option>Lawyer</option>
        <option>Student</option>
      </DataListInput>
      Your email *
      <FormInput
        type="email"
        required
        placeholder="example@gmail.com"
        hidden={false}
      />
      <p style={{ fontSize: 12, color: "red", marginTop: 5 }}>{error}</p>
      <div
        style={{
          display: "flex",
          fontWeight: "200",
          margin: "25px 0px",
          alignItems: "center",
        }}
      >
        <input type={"checkbox"} required style={{ marginRight: 10 }} /> I
        accept{" "}
        <a
          href={
            "https://drive.google.com/file/d/1B5kXBMVkKWNNCGJ6drTRtuAWe4tLt5c-/view?usp=sharing"
          }
          target="_blank"
          style={{ color: "black", marginLeft: 5 }}
        >
          Cookies Policy
        </a>
        ,
        <a
          href={
            "https://drive.google.com/file/d/14bm0G3HaAIxiBvsGyeGxDdmvr9ymZE-3/view?usp=sharing"
          }
          target="_blank"
          style={{ color: "black", margin: "0 5px" }}
        >
          Privacy Policy
        </a>
        and
        <a
          href={
            "https://drive.google.com/file/d/11h6TJksZmwRGPTIYvXN6lFsNWylYUehq/view?usp=sharing"
          }
          target="_blank"
          style={{ color: "black", marginLeft: 5 }}
        >
          Terms and Conditions
        </a>
      </div>
      <GlobalButton
        background={colors.primary}
        color="white"
        border={colors.primary}
        //   onClick={() => setOn((on) => (on++ === 3 ? 1 : on++))}
        type="submit"
      >
        {loading ? (
          <span style={{ padding: 10, marginTop: -10, marginBottom: 7 }}>
            <PropagateLoader color={"white"} loading={loading} size={15} />
          </span>
        ) : (
          <>
            Continue{" "}
            <HiArrowNarrowRight
              size={15}
              color="white"
              style={{ marginLeft: 10 }}
            />
          </>
        )}
      </GlobalButton>
    </AccessForm>
  );
};

export default Step00;
