import React, { useRef, useState } from "react";
import { PropagateLoader } from "react-spinners";
import { colors } from "../../utils/colors";
import { AccessForm, FormInput } from "../styles/Access";
import { GlobalButton } from "../styles/Global";
import { RiSecurePaymentLine } from "react-icons/ri";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useExternalAPI } from "../../hooks/useExternalAPI";
import { useEffect } from "react";
import validator from "validator";
import { useAuth } from "../../context/AuthProvider";

const ChangePassword = ({ setChange }) => {
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(120);
  const [resend, setResend] = useState(false);
  const [error, setError] = useState("");
  const [strong, setStrong] = useState(false);
  const [complete, setComplete] = useState(false);
  const form = useRef();
  const { user, navigate } = useAuth();
  const { emailaddress } = user;
  const email = emailaddress;
  const { getResetOTP, changePassword } = useExternalAPI();

  const request = async (e) => {
    e.preventDefault();
    if (loading) return;
    setError("");
    setLoading(true);

    if (e.target[1].value !== e.target[2].value) {
      setError("Password mismatch!");
      setLoading(false);
      return;
    }

    const request = await changePassword({
      currentpassword: e.target[0].value,
      newpassword: e.target[1].value,
      newpasswordconfirm: e.target[2].value,
    });

    if (request?.success === true) {
      // setStep(3);
      setComplete(true);
    } else {
      setStrong(false);
      setError(request?.message);
    }
    setLoading(false);
  };

  const resendOTP = async () => {
    setError("");
    const request = await getResetOTP(email);
    // console.log("Request response: ", request);
    if (request?.success === true) {
      setResend(false);
      setTimer(120);
    } else if (request?.success === false) setError(request?.message);
    else setError("An error occured, try again later");
  };

  const validate = (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setStrong(true);
      setError("Strong password");
    } else {
      setStrong(false);
      setError("Weak password");
    }
  };

  return (
    <AccessForm
      key={1}
      onSubmit={(e) => (!false ? request(e) : null)}
      ref={form}
    >
      {complete ? (
        <>
          <h3 style={{ textAlign: "center", marginBottom: 30 }}>Successfull</h3>
          <label style={{ color: "grey", fontSize: 12, textAlign: "center" }}>
            Account {email} password has been changed successfully.
          </label>
        </>
      ) : (
        <>
          <h3 style={{ textAlign: "center", marginBottom: 30 }}>
            Password Change
          </h3>
          Current Password *
          <FormInput type="password" placeholder="**********" required />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 5,
              marginBottom: 30,
              flexWrap: "wrap",
            }}
          >
            <label style={{ color: "grey", fontSize: 12 }}>
              verify the current password of this account {email}.
            </label>
          </div>
          New Password *
          <FormInput
            type="password"
            required
            hidden={false}
            onChange={(e) => validate(e.target.value)}
            style={{ marginBottom: 10 }}
            placeholder="**********"
          />
          Confirm password *
          <FormInput
            type="password"
            required
            hidden={false}
            placeholder="**********"
          />
          <p
            style={{
              fontSize: 12,
              color: strong ? "#308D46" : "red",
              marginTop: 5,
            }}
          >
            {error}
          </p>
          <GlobalButton
            background={colors.primary}
            color="white"
            border={colors.primary}
            //   onClick={() => setOn((on) => (on++ === 3 ? 1 : on++))}
            type="submit"
            style={{ marginTop: 20 }}
          >
            {loading ? (
              <span style={{ padding: 10, marginTop: -10, marginBottom: 7 }}>
                <PropagateLoader color={"white"} loading={loading} size={15} />
              </span>
            ) : (
              <>
                Change password{" "}
                <RiSecurePaymentLine
                  size={15}
                  color="white"
                  style={{ marginLeft: 10 }}
                />
              </>
            )}
          </GlobalButton>
        </>
      )}
    </AccessForm>
  );
};

export default ChangePassword;
